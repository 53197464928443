<script setup>
import { Head } from '@inertiajs/vue3';
import {onMounted, ref} from "vue";
import Sidebar from "@/Components/Homepage/Sidebar.vue";
import VideoCard from "@/Components/Videos/VideoCard.vue";
import AuthenticatedLayout from "@/Layouts/AuthenticatedLayout.vue";
import PostCard from "@/Components/Feed/PostCard.vue";
import {windowService} from "@/services/windowService.js";
import SkeletonPost from "@/Components/Feed/SkeletonPost.vue";
import SkeletonVideo from "@/Components/Videos/SkeletonVideo.vue";

defineProps({
    canLogin: {
        type: Boolean,
    },
    canRegister: {
        type: Boolean,
    },
    laravelVersion: {
        type: String,
        required: true,
    },
    phpVersion: {
        type: String,
        required: true,
    },
});

const isLoadingVideos = ref(true)
const isLoadingArticles = ref(true)
const isLoadingFeedPosts = ref(true)

const videos = ref([])
const articles = ref([])
const feedPosts = ref([])

const getVideos = () => {
    axios.get('/api/videos').then(response => {
        console.log(response.data);
        videos.value = response.data.data;
        isLoadingVideos.value = false;
    }).catch(error => {
        console.error(error);
    });
}

const getArticles = () => {
    axios.get('/api/article').then(response => {
        console.log(response.data);
        articles.value = response.data.data;
        isLoadingArticles.value = false;
    }).catch(error => {
        console.error(error);
    });
}

const getFeedPosts = () => {
    axios.get('/api/feed').then(response => {
        console.log(response.data);
        feedPosts.value = response.data.data;
        isLoadingFeedPosts.value = false;
    }).catch(error => {
        console.error(error);
    });
}

const isMobile = windowService();

onMounted(() => {
    getVideos();
    getArticles();
    getFeedPosts();
});
</script>

<template>
    <Head title="WideZike · Homepage of the Internet" />

    <AuthenticatedLayout>
        <div class="flex">
            <Sidebar />
            <main class="flex-1 px-6 py-8 max-w-[1920px]" :class="{'ml-20': !isMobile}">
                <!-- Hero Section -->
                <section class="mb-12">
                    <div class="flex items-center justify-between mb-8">
                        <h2 class="text-2xl font-bold text-gray-100">Trending Now</h2>
                        <a href="/explore" class="text-purple-400 hover:text-purple-300 font-medium">View All</a>
                    </div>
                    <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-6">
                        <VideoCard v-for="video in videos" :key="video.id" :video="video" />
                    </div>

                    <!-- Show Skeletons When Loading -->
                    <div v-if="isLoadingVideos">
                        <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-6">
                            <SkeletonVideo v-for="n in 20" :key="n"></SkeletonVideo>
                        </div>
                    </div>
                </section>

                <!-- Features Section -->
                <section class="mb-12">
                    <div class="flex items-center justify-between mb-8">
                        <h2 class="text-2xl font-bold text-gray-100">Featured Articles</h2>
                        <a href="/articles" class="text-purple-400 hover:text-purple-300 font-medium">View All</a>
                    </div>
                    <div class="grid grid-cols-1 md:grid-cols-2 gap-8">
                        <a :href="'/articles/' + article.slug" v-for="article in articles" :key="article?.id" class="cursor-pointer group bg-gray-900/30 backdrop-blur-sm rounded-xl overflow-hidden border border-gray-800/30 hover:border-purple-500/50 hover:bg-gray-900/50 transition-all duration-300">
                            <div class="relative">
                                <img :src="article?.thumbnail || '/images/default.png'" :alt="article?.name" class="w-full h-48 object-cover group-hover:scale-105 transition-transform duration-300" />
                            </div>
                            <div class="p-6">
                                <div class="mb-3">
                                  <span v-for="tag in article?.tags" class="mr-2 mb-2 px-3 py-1 bg-purple-500/10 text-purple-400 rounded-full text-center text-xs font-medium">
                                    {{ tag }}
                                  </span>
                                </div>
                                <h3 class="font-semibold text-xl mb-2 text-gray-100 group-hover:text-purple-400 transition-colors">
                                    {{ article?.title }}
                                </h3>
                                <p class="text-gray-400 text-sm mb-1">{{ article?.user?.name || 'Unknown' }}</p>
                                <p class="text-gray-500 text-sm">{{ article?.estimated_reading_time || '' }} minute read</p>
                            </div>
                        </a>
                    </div>
                </section>

                <!-- Feed Section -->
                <section>
                    <div class="flex items-center justify-between mb-8">
                        <h2 class="text-2xl font-bold text-gray-100">Your Feed</h2>
                        <a href="/feed" class="text-purple-400 hover:text-purple-300 font-medium bg-purple-500/10 px-4 py-2 rounded-full hover:bg-purple-500/20 transition-all duration-300">
                            What's on your mind?
                        </a>
                    </div>
                    <div class="space-y-6">
                        <PostCard
                            v-for="post in feedPosts"
                            :key="post.id"
                            :post="post"
                        />
                    </div>
                </section>
            </main>
        </div>
    </AuthenticatedLayout>
</template>
